import React, { memo, useEffect } from "react";
import { ReactComponent as WarningIcon } from "images/icons/error.svg";
import { WARNING_500 } from "components/colors";
import CustomNotification from "./styled";

export default memo(({ className = "" }) => {
  return (
    <CustomNotification
      className={className}
      customIcon={<WarningIcon fill={WARNING_500} />}
      title="NEW UPDATE!"
      desc={
        <>
          <p>
              Product Circle Acrylic Ornament, Accent Mug (Light Pink, Red) is temporarily out of stock. Orders created before Dec 06 will still be printed and shipped to your end customers.
          </p>
        </>
      }
    />
  );
});
