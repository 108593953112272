/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Modal, Table, Spin, Avatar, Dropdown, Tooltip, notification } from 'antd';
import newIcon from 'images/new.png';
import { Link } from 'react-router-dom';
import {
  getOptionLabel,
  match_product,
  options_payment_status,
  postfix,
} from 'utils/options';
import money from 'utils/money';
import FormEditAddress from './components/FormEditAddress';
import {
  unapproveOrder,
  archiveOrder,
  approveOrder,
  createIssue,
  getPaymentOrderListAction,
  closeModalWarningPaymentAction,
} from '../List/actions';
import FormMarkValidAddress from './components/MarkValidAddress';
import {
  updateShippingService,
  duplicateOrder,
  lockOrder,
  getDetail,
  updateAddress,
  markAsValidAddressAction,
  cancelOrderAction,
} from './actions';
import { rollbackRemovedOrderItem } from '../ItemsRemoved/actions';
import getMomentInstance from 'utils/moment';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import RenderEditBarcodeFile from 'utils/Form/RenderEditBarcodeFile';
import RenderOrderStatus from 'utils/Form/RenderOrderStatus';
import RenderRemainingAutoLockOrder from 'utils/Form/RenderRemainingAutoLockOrder';
import RenderEditGiftMessage from 'utils/Form/RenderEditGiftMessage';
import RenderDuplicatedOrders from 'utils/Form/RenderDuplicatedOrders';
import { Wrapper } from './styled';
import { GMButton } from 'components/Button';
import RenderShippingInfo from 'utils/Form/components/RenderShippingInfo';
import RenderHistoryOfOrder from 'utils/Form/components/RenderHistoryOfOrder';
import RenderWebhookLogs from 'utils/Form/components/RenderWebhookLogs';
import { ContextMenuWrapper } from '../List/styled';
import { ReactComponent as DownIcon } from 'images/icons/chevron-down.svg';
import { GlobalStyle } from './styled';
import RenderPreviewOrderDetail from 'utils/Form/components/RenderPreviewOrderDetail';
import { listCountry } from 'scenes/Users/scenes/Profile/actions';
import { NO_COLOR, WARNING_50, WARNING_500 } from 'components/colors';
import { GMBadge } from 'components/Badge';
import {
  checkVariantRiskAction,
  closeVariantRiskAction,
  resetReducerAction,
} from '../Create/actions';
import GMHelmet from 'components/GMHelmet';
import { IconWrapper } from 'utils/Form/RenderOrderPlatformInfo.styled';
import { ServiceLabel } from 'constants/service-label';
import { getListLogsOrder, getListLogsWebhook } from 'scenes/Logs/actions';
import { ReactComponent as Icon } from 'images/icons/alert-circle.svg';
import ConfirmDialog from 'components/Modal/ConfirmDialog';
import { ConfirmModalInnerWrapper } from 'components/Modal/ConfirmDialog/styled';
import GMAvatar from 'components/GMAvatar';
import { fncCondRemap, invalidActionNotify } from 'scenes/Orders/utils';

const { confirm } = Modal;
export const getOrderItemActions = (dispatch) => ({
  getDetail: (id) => {
    dispatch(getDetail(id));
  },
  updateAddress: (id, params) => {
    dispatch(updateAddress(id, params));
  },
  unapproveOrder: (data) => {
    dispatch(unapproveOrder(data));
  },
  archiveOrder: (data) => {
    dispatch(archiveOrder(data));
  },
  duplicateOrder: (data) => {
    dispatch(duplicateOrder(data));
  },
  updateShippingService: (id, data) => {
    dispatch(updateShippingService(id, data));
  },
  lockOrder: (id) => {
    dispatch(lockOrder(id));
  },
  markAsValidAddressAction: (id) => {
    dispatch(markAsValidAddressAction(id));
  },
  createIssue: (data) => {
    dispatch(createIssue(data));
  },
  approveOrder: (data) => {
    dispatch(approveOrder(data));
  },
  rollbackRemovedOrderItem: (data, params) => {
    dispatch(rollbackRemovedOrderItem(data, params));
  },
  listCountry: () => {
    dispatch(listCountry());
  },
  checkVariantRiskAction: (data) => {
    dispatch(checkVariantRiskAction(data));
  },
  closeVariantRiskAction: () => {
    dispatch(closeVariantRiskAction());
  },
  closeModalWarningPaymentAction: () => {
    dispatch(closeModalWarningPaymentAction());
  },
  resetReducerAction: () => {
    dispatch(resetReducerAction());
  },
  getPaymentOrderListAction: (data) => {
    dispatch(getPaymentOrderListAction(data));
  },
  cancelOrderAction: (data) => {
    dispatch(cancelOrderAction(data));
  },
  getListLogsWebhook: (data) => {
    dispatch(getListLogsWebhook(data));
  },
  getListLogsOrder: (data) => {
    dispatch(getListLogsOrder(data));
  },
});
class Detail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: null,
      listShippingService: [],
      record2Remove: null,
    };
  }

  componentDidMount() {
    this.props.getDetail(this.props.match.params.id);
    this.props.listCountry();
    this.props.getListLogsWebhook(this.props.match.params.id);
    this.props.getListLogsOrder(this.props.match.params.id);
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      parseInt(this.props.match.params.id) !==
      parseInt(prevProps.match.params.id)
    ) {
      this.props.getDetail(this.props.match.params.id);
    }
    if (
      (this.props.checkAddress?.valid === true &&
        !prevProps?.checkAddress?.valid) ||
      (prevProps?.checkAddress?.valid === true &&
        this.props.checkAddress?.valid === true &&
        !prevProps?.checkAddress?.valid)
    ) {
      this.setState({ showModal: false });
    }
    if (prevProps?.detail?.data !== this.props.detail?.data) {
      this.props.getListLogsOrder(this.props.match.params.id);
    }
  }

  handleUpdateAddress = ({
    cusName,
    cusEmail,
    cusPhone,
    cusCompany,
    cusAddress,
    cusCountry,
    cusPostalCode,
    cusAddress2,
    cusCity,
    cusProvince,
    iossNumberTotal,
    buyerTaxAmount,
    buyerTaxNumber,
    iossNumber,
    shippingMethod,
  }) => {
    this.props.updateAddress(this.props.match.params.id, {
      cusName,
      cusEmail,
      cusPhone,
      cusCompany,
      cusAddress,
      cusCountry,
      cusPostalCode,
      cusAddress2,
      cusCity,
      cusState: cusProvince,
      orderValue: iossNumberTotal,
      taxNumber: buyerTaxNumber,
      orderTaxValue: buyerTaxAmount,
      iossNumber,
      shippingMethod,
    });
  };
  handleChangeShippingService = (values) => {
    this.props.updateShippingService(this.props.match.params.id, {
      shippingMethod: values.shippingMethod || 0,
    });
  };
  formMarkValidAddress = () => {
    this.props.markAsValidAddressAction({ order: this.props.match.params.id });
    this.setState({ showModal: false });
  };

  archiveOrder = () => {
    const {
      archiveOrder,
      match: {
        params: { id },
      },
    } = this.props;
    confirm({
      title: 'Do you want to archive this order?',
      icon: <ExclamationCircleOutlined />,
      content: "You won't be able to revert this!",
      onOk() {
        archiveOrder({ orders: [id], from: 'detailOrder' });
      },
      onCancel() {},
    });
  };

  // handleSubmit = (values) => {
  //   let data = [];
  //   let newArray = values?.listOrderItems?.map(function (i) {
  //     return data.push({
  //       exp_id: parseInt(i?.productId),
  //       color_id: parseInt(i?.colorId),
  //       size_id: parseInt(i?.sizeId),
  //     });
  //   });
  //   this.props.checkVariantRiskAction({
  //     data: { data: data },
  //     submitData: this.props.match.params.id,
  //     form: 'duplicate_order_manual',
  //   });
  // };

  handleDuplicateOrderManual = () => {
    window.scroll(0, 0);
    this.props.closeVariantRiskAction();
    this.props.duplicateOrder({ id: this.props.match.params.id });
    this.props.resetReducerAction();
  };

  handleCloseModal = () => {
    this.props.closeVariantRiskAction();
  };
  //Handle modal check make payment
  handleClosePaymentModal = () => {
    this.props.closeModalWarningPaymentAction();
  };

  duplicateOrder = () => {
    if (parseInt(this.props.detail?.data?.platformId) === 9) {
      this.props.history.push(
        `/orders/${this.props.detail?.data?.id}/duplicate`
      );
    } else {
      let data = [];
      this.props.checkVariantRiskAction({
        data: { data: data },
        submitData: this.props.match.params.id,
        form: 'duplicate_order_manual',
      });
    }
  };

  convertOrderLabel = () => {
    this.props.history.push(
      `/orders/${this.props.detail?.data?.id}/convert-order-label`
    );
  };

  lockOrder = () => {
    const {
      lockOrder,
      match: {
        params: { id },
      },
    } = this.props;
    confirm({
      title: 'Do you want to lock this order?',
      icon: <ExclamationCircleOutlined />,
      content: "You won't be able to revert this!",
      onOk() {
        lockOrder(id);
      },
      onCancel() {},
    });
  };

  approveOrder = () => {
    const {
      approveOrder,
      match: {
        params: { id },
      },
    } = this.props;
    confirm({
      title: 'Do you want to approve this order?',
      icon: <ExclamationCircleOutlined />,
      content: "You won't be able to revert this!",
      onOk() {
        approveOrder({ orders: [id], from: 'detailOrder' });
      },
      onCancel() {},
    });
  };

  unapproveOrder = () => {
    const {
      unapproveOrder,
      match: {
        params: { id },
      },
    } = this.props;
    confirm({
      title: 'Do you want to unapprove this order?',
      icon: <ExclamationCircleOutlined />,
      content: "You won't be able to revert this!",
      onOk() {
        unapproveOrder({ orders: [id], from: 'detailOrder' });
      },
      onCancel() {},
    });
  };
  cancelOrder = () => {
    const {
      cancelOrderAction,
      match: {
        params: { id },
      },
    } = this.props;
    cancelOrderAction({ orderId: id });
    this.setState({ record2Remove: null });
  };

  createIssue = () => {
    const redirectToCreateIssue = () => {
      this.props.history.push('/orders/order-issues/create', {
        listOrderId: [this.props.match.params.id],
      });
    };
    confirm({
      title: 'Do you want to create issue for these orders?',
      icon: <ExclamationCircleOutlined />,
      content: "You won't be able to revert this!",
      onOk() {
        //this.props.createIssue({orders: ids})
        redirectToCreateIssue();
      },
      onCancel() {},
    });
  };
  viewInvoice = () => {
    this.props.history.push(`/orders/${this.props.match.params.id}/invoice`);
  };

  paymentOrder = () => {
    this.props.getPaymentOrderListAction({
      orders: [this.props.match.params.id],
      form: 'detail_order',
    });
  };

  render() {
    const {
      detail,
      updateAddressLoading,
      duplicateOrderLoading,
      lockOrderLoading,
      rollbackLoading,
      approveOrderLoading,
      unapproveOrderLoading,
      paymentOrderLoading,
      countries,
      pageName,
      markAsValidAddressLoading,
      checkAddress,
      orderNewUpdate,
      showModalVariant,
      listVariantRiskLoading,
      listVariantRisk,
      showErrorPayment,
      listPaymentLoading,
      listPayment,
      logsWebhook,
      logsWebhookLoading,
      logsOrder,
      logsOrderLoading,
      profile,
      cancelOrderLoading,
    } = this.props;
    const { showModal } = this.state;
    const columns = [
      {
        title: 'Product name',
        dataIndex: 'content',
        key: 'content',
        render: (value, record) => {
          return (
            <div>
              <b>{record?.exProduct?.brand || ''}</b>
              <p style={{ color: '#475467' }}>
                {typeof record?.color2?.name === 'string'
                  ? record?.color2?.name
                  : 'Color: null'}{' '}
                -{' '}
                {typeof record?.size2?.name === 'string'
                  ? record?.size2?.name
                  : 'Size: null'}{' '}
                - Print side: {record?.printSide2 || ''}
              </p>
              {parseInt(detail?.data?.platformId) === 9 && (
                <>
                  <RenderEditBarcodeFile
                    showLinkFile={true}
                    currBarcodeUrl={record?.linkBarcode}
                    orderId={detail.data.id}
                    fullwidth={false}
                    editable={
                      (parseInt(detail.data.locked) === 0 &&
                        parseInt(detail.data.isApproved) === 0 &&
                        parseInt(detail.data.paymentStatus) === 0) ||
                      !detail?.data?.onHoldReason?.reason_id === 12
                    }
                    orderItemId={record.id}
                    isEditOrderItemBarCode={true}
                  />
                </>
              )}
            </div>
          );
        },
      },
      {
        title: 'Mockup',
        dataIndex: 'mainUrl',
        align: 'center',
        key: 'mainUrl',
        render: (text, record) => {
          return (
            <div>
              <img
                width="50"
                src={text}
                alt=""
                style={{
                  backgroundColor: record.hexColor
                    ? `#${record.hexColor}`
                    : 'transparent',
                }}
              />
            </div>
          );
        },
      },
      {
        title: 'Design',
        dataIndex: 'frontDesignUrl',
        key: 'frontDesignUrl',
        align: 'center',
        render: (src, record) => {
          const printLocation = record?.exProduct?.printLocation
            ?.split(',')
            ?.filter((p) => p && p.length > 0);
          if (!printLocation?.includes('front')) {
            return null;
          }
          return src ? (
            <div>
              <GMAvatar shape="square" size={64} src={src} />
              <RenderPreviewOrderDetail
                colorHex={
                  record.hexColor ? `#${record.hexColor}` : 'transparent'
                }
                orderItemId={record.id}
                orderId={record.orderId}
                exProductId={record.expId}
                colorId={record.color}
                sidePrint={'front'}
                sizeId={record.size}
                typePrint={record.printType}
                productName={record?.exProduct?.brand}
                canRender={
                  record?.exProduct?.productDisplay * 1 ||
                  record?.exProduct?.display * 1
                }
              />
            </div>
          ) : null;
        },
      },
      {
        dataIndex: 'backDesignUrl',
        key: 'backDesignUrl',
        align: 'center',
        render: (src, record) => {
          const printLocation = record?.exProduct?.printLocation
            ?.split(',')
            ?.filter((p) => p && p.length > 0);
          if (!printLocation?.includes('back')) {
            return null;
          }
          return src ? (
            <div>
              <GMAvatar shape="square" size={64} src={src} />
              <RenderPreviewOrderDetail
                colorHex={
                  record.hexColor ? `#${record.hexColor}` : 'transparent'
                }
                orderItemId={record.id}
                orderId={record.orderId}
                exProductId={record.expId}
                colorId={record.color}
                sidePrint={'back'}
                sizeId={record.size}
                typePrint={record.printType}
                canRender={
                  record?.exProduct?.productDisplay * 1 ||
                  record?.exProduct?.display * 1
                }
                productName={record?.content?.name_brand}
              />
            </div>
          ) : null;
        },
      },
      {
        title: 'Quantity',
        dataIndex: 'quantity',
        align: 'center',
        key: 'quantity',
      },
      {
        title: 'Unit price',
        dataIndex: 'price',
        key: 'price',
        align: 'right',
        width: 120,
        render: (value, record) => {
          return (
            <>
              <div>{money(value + record.extraCost + record.printCost)}</div>
              {(record?.originalPrice || 0) > 0 && (
                <div className={'text-danger'}>
                  <del>{money(record?.originalPrice || 0)}</del>
                </div>
              )}
              {record.frontDesignUrl.length !== 0 &&
                record.backDesignUrl.length !== 0 && (
                  <div className="text-muted"> incl. extra design fee</div>
                )}
            </>
          );
        },
      },
    ];
    const removedOrderItemsColumns = [
      {
        title: 'Product name',
        dataIndex: 'content',
        key: 'content',
        render: (value) => (
          <div>
            {value?.name_brand}
            <div>{value?.name_product}</div>
          </div>
        ),
      },
      {
        title: 'Mockup',
        dataIndex: 'mainUrl',
        align: 'center',
        key: 'mainUrl',
        render: (text, record) => {
          return (
            <div>
              <img
                width="50"
                src={text}
                alt=""
                style={{
                  backgroundColor: record.hexColor
                    ? `#${record.hexColor}`
                    : 'transparent',
                }}
              />
            </div>
          );
        },
      },
      {
        title: 'Design',
        dataIndex: 'frontDesignUrl',
        key: 'frontDesignUrl',
        align: 'center',
        render: (src) =>
          src ? (
            <div>
              <GMAvatar shape="square" size={64} src={src} />
            </div>
          ) : null,
      },
      {
        dataIndex: 'backDesignUrl',
        key: 'backDesignUrl',
        align: 'center',
        render: (src) =>
          src ? (
            <div>
              <GMAvatar shape="square" size={64} src={src} />
            </div>
          ) : null,
      },
      {
        title: 'Quantity',
        dataIndex: 'quantity',
        align: 'center',
        key: 'quantity',
      },
      {
        title: 'Price',
        dataIndex: 'price',
        align: 'right',
        key: 'price',
        render: (value, record) => {
          return (
            <>
              <div>{money(value + record.extraCost + record.printCost)}</div>
              {(record?.originalPrice || 0) > 0 && (
                <div className={'text-danger line-through'}>
                  <del>{money(record?.originalPrice || 0)}</del>
                </div>
              )}
            </>
          );
        },
      },
      {
        title: 'Action',
        key: 'action',
        align: 'center',
        render: (text, record) => (
          <div>
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => {
                this.props.rollbackRemovedOrderItem({
                  orderItems: [record.id],
                  orderId: this.props.match.params.id,
                });
              }}
            >
              Rollback
            </button>
          </div>
        ),
      },
    ];
    const orderExtraChargeColumns = [
      {
        title: 'Transaction name',
        dataIndex: 'transactionId',
        align: 'left',
        key: 'transactionId',
        render: (text, record) => (
          <div>
            <Link to={`/billing/transactions/${text}/detail`}>
              {record?.transactionName || text}
            </Link>
          </div>
        ),
      },
      {
        title: 'Time',
        dataIndex: 'updateTime',
        align: 'left',
        key: 'updateTime',
        render: (text) => getMomentInstance(text, 0, true),
      },
      {
        title: 'Amount',
        dataIndex: 'totalDifference',
        align: 'left',
        key: 'totalDifference',
        render: (text) => money(text || 0),
      },
    ];
    let checkCanvas = false;
    let listCountryAvalibleCanvasIt = false;
    const remapFuncs = fncCondRemap([detail?.data], {
      approveOrder: this.approveOrder.bind(
        this,
        detail.data.id
      ),
      unapproveOrder: this.unapproveOrder,
      viewInvoice: this.viewInvoice,
      lockOrder: this.lockOrder,
      createIssue: this.createIssue,
      convertOrderLabel: this.convertOrderLabel,
      duplicateOrder: this.duplicateOrder,
    }, () => notification.warning({ message: invalidActionNotify, duration: 5 }));
    return (
      <>
        {detail?.data?.name ? (
          <GMHelmet overrideTitle={detail?.data?.name + postfix} />
        ) : null}
        <Wrapper
          pageName={
            (detail?.data?.name && `#${detail?.data?.name}`) || pageName
          }
          ctas={
            <>
              {((detail.data.paymentStatus === 0 && profile?.primaryId === 0) ||
                (detail.data.paymentStatus === 0 &&
                  profile?.primaryId !== 0 &&
                  profile.paymentOrderPermission === 1)) && (
                <GMButton onClick={this.paymentOrder} className="mr-2">
                  Make payment
                </GMButton>
              )}
              <Dropdown
                trigger="click"
                overlay={
                  <ContextMenuWrapper>
                    {detail.data.platformId && detail.data.platformId > 0 && (
                      <div>
                        {parseInt(detail.data.isApproved) === 0 &&
                          parseInt(detail.data?.orderMatch?.isImport) === 1 &&
                          parseInt(detail.data.transactionId) === 0 && (
                            <Link
                              onClick={remapFuncs.approveOrder}
                            >
                              Approve order
                            </Link>
                          )}
                        {parseInt(detail.data.locked) === 0 &&
                          parseInt(detail.data.isApproved) === 1 &&
                          parseInt(detail.data.paymentStatus) === 0 &&
                          parseInt(detail.data.transactionId) === 0 && (
                            <Link onClick={remapFuncs.unapproveOrder}>
                              Unapprove order
                            </Link>
                          )}

                        {parseInt(detail.data.paymentStatus) === 1 &&
                          parseInt(detail.data.locked) === 1 && (
                            <Link onClick={remapFuncs.viewInvoice}>View invoice</Link>
                          )}
                        {parseInt(detail.data.locked) === 0 &&
                          parseInt(detail.data.verifiedAddress) === 1 &&
                          parseInt(detail.data.transactionId) !== 0 &&
                          parseInt(detail.data.paymentStatus) === 0 &&
                          parseInt(detail.data?.orderMatch?.isImport) !== 2 &&
                          parseInt(detail.data.isApproved) === 0 &&
                          parseInt(detail.data.unlockedAt) !== 0 && (
                            <Link onClick={remapFuncs.lockOrder}>Lock order</Link>
                          )}
                        {detail?.data?.hasNotCompletedIssue === false &&
                          parseInt(detail?.data?.transactionId) !== 0 && (
                            <Link onClick={remapFuncs.createIssue}>Create issue</Link>
                          )}

                        {parseInt(detail?.data?.platformId) !== 17 &&
                          parseInt(detail?.data?.locked) === 0 &&
                          parseInt(detail?.data?.isApproved) === 0 &&
                          parseInt(detail?.data?.transactionId) === 0 &&
                          checkCanvas === false && (
                            profile.allowLabelOrder === 0 ?
                            <Tooltip title="Label order is not available">
                              <Link className="disable-link" onClick={ (event) => event.preventDefault() }>
                                Convert to label order
                              </Link>
                            </Tooltip>
                            :
                            <Link onClick={remapFuncs.convertOrderLabel}>
                              Convert to label order
                            </Link>
                          )}

                        {parseInt(detail?.data?.platformId) === 9 ||
                          parseInt(detail?.data?.platformId) !== 17 ? (
                          <Link onClick={remapFuncs.duplicateOrder}>
                            Duplicate order
                          </Link>
                        ) : (
                          ''
                        )}
                        {detail.data.transactionId !== 0 &&
                        (detail?.data?.customStatus  === 0 ||
                          detail?.data?.customStatus  === 13 ||
                          detail?.data?.customStatus  === 17) ? (
                          <Link
                            onClick={() =>
                              this.setState({ record2Remove: detail?.data })
                            }
                          >
                            Request cancel
                          </Link>
                        ) : (
                          ''
                        )}
                      </div>
                    )}
                  </ContextMenuWrapper>
                }
                placement="bottomLeft"
                arrow
              >
                <GMButton
                  type="button"
                  className="moreactions"
                  color="SecondaryGray"
                >
                  More actions
                  <DownIcon />
                </GMButton>
              </Dropdown>
            </>
          }
          breadcrumDetails={{
            [detail.data.id]: detail?.data?.name,
          }}
          noContainer
        >
          <Spin
            spinning={
              detail.loading ||
              duplicateOrderLoading ||
              lockOrderLoading ||
              rollbackLoading ||
              approveOrderLoading ||
              unapproveOrderLoading ||
              paymentOrderLoading ||
              markAsValidAddressLoading ||
              cancelOrderLoading
            }
          >
            <div className="time-block d-block d-sm-flex mb-3 justify-content-between align-items-center ">
              <div className="time">
                Created:{' '}
                {detail.data.createdAt && detail.data.createdAt
                  ? getMomentInstance(detail.data.createdAt, null, true)
                  : 'N/A'}
                {parseInt(detail.data?.showPolicy) === 1 && (
                  <div>PII data has been removed due to Amazon PII Policy</div>
                )}{' '}
                - Last updated:{' '}
                {detail.data.updatedAt && detail.data.updatedAt
                  ? getMomentInstance(detail.data.updatedAt, null, true)
                  : 'N/A'}
              </div>
              <div className="mb-2 mb-sm-0">
                {Array.isArray(detail?.data?.orderDuplicate) &&
                  detail?.data?.orderDuplicate.length > 0 && (
                    <RenderDuplicatedOrders orderId={detail?.data?.id || 0} />
                  )}
              </div>
            </div>
            <div className="pb-3">
              <div className="px-1 px-lg-2">
                <div className="row">
                  <div className="col-md-12 col-xl-9 mb-3 ml-0 pl-0">
                    {detail?.data ? (
                      <div className="mb-4 table">
                        <Table
                          className="main-content"
                          pagination={false}
                          rowKey="id"
                          columns={columns}
                          dataSource={detail.data.orderItems || []}
                          scroll={{ x: 1000 }}
                          summary={() => {
                            return (
                              <>
                                <tr>
                                  <td
                                    className="bg-light py-1 border-0 fl"
                                    colSpan={4}
                                  >
                                    Sub-total (
                                    {(detail.data.orderItems || []).length} item
                                    {(detail.data.orderItems || []).length > 1
                                      ? 's'
                                      : ''}
                                    )
                                  </td>
                                  <td className="bg-light py-1 border-0 text-center fl">
                                    {detail.data.orderExtra?.itemsQuantity}
                                  </td>
                                  <td className="bg-light py-1 border-0 colprice fl">
                                    {money(detail.data.subTotal)}
                                  </td>
                                </tr>
                                <tr>
                                  <td
                                    className="bg-light py-1 border-0"
                                    colSpan={5}
                                  >
                                    Shipping Fee
                                  </td>
                                  <td className="bg-light py-2 border-0 colprice">
                                    {money(detail.data.shippingFee || 'N/A')}
                                  </td>
                                </tr>
                                <tr>
                                  <td
                                    className="bg-light py-1 border-0"
                                    colSpan={5}
                                  >
                                    Handle Fee
                                  </td>
                                  <td className="bg-light py-2 border-0 colprice">
                                    {money(detail.data.orderHandleFee || 'N/A')}
                                  </td>
                                </tr>
                                <tr>
                                  <td
                                    className="bg-light py-1 border-0"
                                    colSpan={5}
                                  >
                                    Gift message fee
                                  </td>
                                  <td className="bg-light py-2 border-0 colprice">
                                    {money(
                                      detail.data?.orderExtend
                                        ?.giftMessagePrice || 'N/A'
                                    )}{' '}
                                  </td>
                                </tr>
                                <tr>
                                  <td
                                    className="bg-light py-1 border-0"
                                    colSpan={5}
                                  >
                                    Tax
                                  </td>
                                  <td className="bg-light py-2 border-0 colprice">
                                    {money(detail.data.totalVat || 'N/A')}
                                  </td>
                                </tr>
                                <tr>
                                  <td
                                    className="bg-light py-1 border-0"
                                    colSpan={5}
                                  >
                                    Discount
                                  </td>
                                  <td className="bg-light py-2 border-0 colprice">
                                    {money(detail.data.totalDiscount || 'N/A')}
                                  </td>
                                </tr>
                                <tr>
                                  <td
                                    className="bg-light py-1 border-0"
                                    colSpan={5}
                                  >
                                    Promotion amount
                                  </td>
                                  <td className="bg-light py-2 border-0 colprice">
                                    {money(detail.data.promotionPrice || 'N/A')}
                                  </td>
                                </tr>
                                {(detail?.data?.orderExtraFee || []).map(
                                  (fee) => {
                                    if (!fee?.amount || fee?.extraType === 0) {
                                      return null;
                                    }
                                    return (
                                      <tr>
                                        <td
                                          className="bg-light py-1 border-0"
                                          colSpan={5}
                                        >
                                          {fee?.extraTypeName}
                                        </td>
                                        <td className="bg-light py-2 border-0 colprice">
                                          {money(fee?.amount || 'N/A')}
                                        </td>
                                      </tr>
                                    );
                                  }
                                )}
                                <tr>
                                  <td
                                    className="bg-light py-1 border-0 font-weight-bold"
                                    colSpan={5}
                                  >
                                    Total
                                  </td>
                                  <td
                                    className="bg-light py-2 border-0 total colprice font-weight-bold"
                                    style={{ color: '#169DD8' }}
                                  >
                                    {money(detail.data.total || 'N/A')}
                                  </td>
                                </tr>
                              </>
                            );
                          }}
                        />
                      </div>
                    ) : (
                      <h5 className="font-size-16">No data</h5>
                    )}
                    {(detail.data.removedOrderItems || []).length > 0 && (
                      <>
                        <h5 className="mb-3 font-weight-bold">
                          Order Items Removed
                        </h5>
                        <div className="mb-4">
                          <Table
                            className="main-content"
                            pagination={false}
                            rowKey="id"
                            columns={removedOrderItemsColumns}
                            dataSource={detail.data.removedOrderItems || []}
                            scroll={{ x: 1000 }}
                          />
                        </div>
                      </>
                    )}
                    {(detail.data.orderExtraCharges || []).length > 0 && (
                      <>
                        <h5 className="mb-3 font-weight-bold">
                          List extra charge
                        </h5>
                        <div className="mb-4">
                          <Table
                            className="main-content"
                            pagination={false}
                            rowKey="id"
                            columns={orderExtraChargeColumns}
                            dataSource={detail.data.orderExtraCharges || []}
                            scroll={{ x: 1000 }}
                          />
                        </div>
                      </>
                    )}

                    <RenderHistoryOfOrder
                      orderId={detail.data.id}
                      logsOrder={logsOrder}
                      loading={logsOrderLoading}
                    />
                    <RenderWebhookLogs
                      orderId={detail.data.id}
                      loading={logsWebhookLoading}
                      logsWebhook={logsWebhook}
                    />
                  </div>
                  <div className="col-md-12 col-xl-3">
                    {(detail?.data?.unlockedAt || 0) +
                      3600 *
                        (parseInt(detail?.data?.customStatus) === 19 ? 12 : 6) -
                      getMomentInstance().unix() >
                      0 && (
                      <div className="mb-4 px-2 px-sm-4 py-4 border rounded">
                        <h5 className="mb-2 font-weight-bold">
                          Remaining auto-lock Order
                        </h5>
                        <RenderRemainingAutoLockOrder
                          startTime={detail?.data?.unlockedAt || 0}
                          duration={
                            parseInt(detail?.data?.customStatus) === 19 ? 12 : 6
                          }
                        />
                      </div>
                    )}
                    <div className="block-right  infor-block">
                      <h5 className="title">Order information</h5>
                      <div className="infor-block ">
                        <div className="row justify-content-between item">
                          <div className="title flex-vertical-center">
                            Store name
                          </div>
                          <div className="content">
                            <IconWrapper>
                              <img
                                src={
                                  ServiceLabel[
                                    detail?.data?.platform?.name?.toLowerCase()
                                  ] || ServiceLabel['n/a']
                                }
                                alt={detail?.data?.platform?.name}
                              />
                            </IconWrapper>
                          </div>
                        </div>
                        <div className="row justify-content-between item">
                          <div className="title">Status</div>
                          <div className="content">
                            <RenderOrderStatus
                              order={detail?.data}
                              isShowError={false}
                            />
                          </div>
                        </div>
                        <div className="row justify-content-between item">
                          <div className="title">
                            Locked{' '}
                            <Tooltip
                              title={
                                <div>
                                  The selected order is locked and cannot be
                                  modified. If you need any assistance, please
                                  visit our helpdesk page for more information.
                                </div>
                              }
                            >
                              <Icon />
                            </Tooltip>
                          </div>
                          <div></div>
                          <div className="content">
                            {detail?.data?.locked === 1 ? 'Yes' : 'No'}
                          </div>
                        </div>
                        <div className="row justify-content-between item">
                          <div className="title">Payment</div>
                          <div className="content">
                            {getOptionLabel(
                              options_payment_status,
                              detail.data.paymentStatus
                            )}
                          </div>
                        </div>
                        <div className="d-flex row justify-content-between item">
                          <div className="title">Match product</div>
                          <div className="content">
                            {getOptionLabel(
                              match_product,
                              parseInt(detail?.data?.orderMatch?.isImport)
                            )}
                          </div>
                        </div>
                        <div className="d-flex row justify-content-between item">
                          <div className="title">Approve time</div>
                          <div className="content">
                            {detail.data.isApproved && detail.data.approvedAt
                              ? getMomentInstance(
                                  null,
                                  detail.data.approvedAt,
                                  true
                                )
                              : 'N/A'}
                          </div>
                        </div>
                        {detail.data.profit > 0 && (
                          <div className="d-flex row justify-content-between item">
                            <div className="title">Profit</div>
                            <div className="content">
                              {money(detail.data.profit)}
                            </div>
                          </div>
                        )}
                        {detail.data.transactionRefund > 0 && (
                          <div className="d-flex row justify-content-between item">
                            <div className="title">Refund transaction</div>
                            <div className="content">
                              <Link
                                to={`/billing/transactions/${detail.data.transactionRefund}/detail`}
                              >
                                View transaction
                              </Link>
                            </div>
                          </div>
                        )}
                        {detail.data?.originalOrder?.order?.name && (
                          <div className="d-flex row justify-content-between item">
                            <div className="title">Duplicate from</div>
                            <div className="content">
                              <Link
                                to={`/orders/${detail.data.originalOrder.orderOriginalId}`}
                                target="_blank"
                              >
                                {detail.data.originalOrder.order.name}
                              </Link>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                    {detail?.data?.cusName && (
                      <div className="block-right">
                        <RenderShippingInfo
                          order={detail.data}
                          _this={this}
                          lockedOrder={detail?.data?.locked}
                          reasonOnHold={detail?.data?.onHoldReason?.reason_id}
                        />
                      </div>
                    )}
                    {/* <div className="block-right">
                      <h5 className="mb-2 font-weight-bold d-flex justify-content-between">
                        <span className="title">Shipping service</span>
                        {parseInt(detail?.data?.platformId) !== 9 &&
                          parseInt(detail?.data?.platformId) !== 17 &&
                          (detail?.data?.locked === 1 ? (
                            <span className="cursor-pointer text-info font-size-14 button-svg disable">
                              <EditIcon />
                            </span>
                          ) : (
                            <span
                              className="cursor-pointer text-info font-size-14 button-svg"
                              onClick={() =>
                                this.setState({
                                  showModal: 'change_shipping_service',
                                })
                              }
                            >
                              <EditIcon />
                            </span>
                          ))}
                      </h5>
                      <div>
                        {detail?.data?.shippingLabel || 'Pickup at warehouse'}
                      </div>
                    </div> */}
                    {(parseInt(detail.data.platformId) === 9 ||
                      parseInt(detail.data.platformId) === 17) && (
                      <div className="block-right">
                        <h5 className="title">Shipping labels</h5>
                        <div className="col-8 col-md-9 col-lg-12 px-lg-0">
                          {detail?.data?.orderBarcodeFiles.map(
                            (orderBarcodeFile) => {
                              if (
                                parseInt(detail?.data?.platformId) === 17 &&
                                parseInt(detail?.data?.status) === 1 &&
                                parseInt(detail?.data?.customStatus) === 12 &&
                                !orderBarcodeFile?.barcodePath
                              ) {
                                return (
                                  <div key={orderBarcodeFile?.id}>
                                    <p className={'text-danger font-italic'}>
                                      *Notice: Please upload vertical shipping
                                      labels with the size of 4x6 inch.
                                      <a
                                        href="https://account.gearment.com/public/docs/label_example.pdf"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                      >
                                        Label example
                                      </a>
                                    </p>
                                    <RenderEditBarcodeFile
                                      currBarcodeUrl={
                                        orderBarcodeFile?.barcodeUrl
                                      }
                                      editable={true}
                                      showLinkFile={true}
                                      orderId={detail.data.id}
                                      orderBarcodeFileId={orderBarcodeFile?.id}
                                    />
                                  </div>
                                );
                              } else {
                                return (
                                  <div key={orderBarcodeFile?.id}>
                                    <RenderEditBarcodeFile
                                      currBarcodeUrl={
                                        orderBarcodeFile?.barcodeUrl
                                      }
                                      editable={
                                        parseInt(detail.data.locked) === 1
                                          ? false
                                          : true
                                      }
                                      linkFileTitle="Shipping label"
                                      showLinkFile={true}
                                      orderId={detail.data.id}
                                      orderBarcodeFileId={orderBarcodeFile?.id}
                                      buttonTitle="Edit shipping labels"
                                    />
                                  </div>
                                );
                              }
                            }
                          )}
                        </div>
                      </div>
                    )}

                    {parseInt(detail.data.locked) === 0 && (
                      <div className="block-right">
                        <RenderEditGiftMessage order={detail.data} />
                      </div>
                    )}
                    <div>
                      {Array.isArray(detail.data.trackingWorkings) &&
                        detail.data.trackingWorkings.length > 0 && (
                          <div className="block-right">
                            <div>
                              <h5 className="title">Trackings</h5>
                              {detail?.data?.trackingWorkings.map(
                                (trackingItem, index) => {
                                  return (
                                    <>
                                      <p
                                        className={
                                          'font-weight-bold font-size-16'
                                        }
                                        style={{
                                          overflowWrap: 'break-word',
                                        }}
                                      >
                                        {index + 1}.{' '}
                                        <a
                                          href={trackingItem.ship_tracking_link}
                                          target={'_blank'}
                                          rel="noopener noreferrer"
                                          style={{ width: '200px' }}
                                        >
                                          {trackingItem.ship_trackingcode}
                                        </a>
                                        {index === 0 && (
                                          <img
                                            className="img-fluid"
                                            width="40px"
                                            src={newIcon}
                                            alt=""
                                          />
                                        )}
                                      </p>
                                      <p>
                                        {' '}
                                        <Tooltip title="Tracking has not been updated in the last 7 days">
                                          {trackingItem?.trackingShipment
                                            .no_update === 1 && (
                                            <GMBadge
                                              color={WARNING_500}
                                              noDot={true}
                                              border={NO_COLOR}
                                              backgroundColor={WARNING_50}
                                              className="badge"
                                              // style={{ width: '62px' }}
                                            >
                                              No update
                                            </GMBadge>
                                          )}
                                        </Tooltip>
                                      </p>
                                      <p>
                                        Buy tracking date:{' '}
                                        {getMomentInstance(
                                          trackingItem?.time_created,
                                          0,
                                          true
                                        )}
                                      </p>
                                      <p>
                                        Status:{' '}
                                        {
                                          trackingItem?.trackingShipment
                                            ?.last_status
                                        }
                                      </p>
                                      <p>
                                        Carrier service:{' '}
                                        {trackingItem?.ship_carrier} -
                                        {trackingItem?.ship_service}
                                      </p>
                                      <p>Type: {trackingItem.typeProduct}</p>

                                      <p>
                                        {trackingItem.link_label_png !==
                                        false ? (
                                          <a
                                            href={trackingItem?.link_label_png}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                          >
                                            Tracking label
                                          </a>
                                        ) : (
                                          ''
                                        )}
                                      </p>
                                    </>
                                  );
                                }
                              )}
                            </div>
                          </div>
                        )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Spin>
          <Modal
            style={{
              top: 20,
            }}
            footer={null}
            visible={showModal === 'edit_address'}
            onCancel={() => this.setState({ showModal: null })}
            width={800}
          >
            <Spin spinning={updateAddressLoading}>
              <FormEditAddress
                onSubmit={this.handleUpdateAddress}
                onCancel={() => this.setState({ showModal: null })}
                initialValues={{
                  ...detail.data,
                  cusCountry: detail?.data?.country?.id || '',
                  shippingMethod: detail?.data?.shippingMethod || '',
                  iossNumber: detail?.data?.orderExtra?.iossNumber || '',
                  iossNumberTotal:
                    detail?.data?.orderExtra?.iossNumberTotal || 0,
                }}
                detail={detail}
                countries={countries}
                checkAddress={checkAddress}
                updateAddressLoading={updateAddressLoading}
                orderNewUpdate={orderNewUpdate}
                orderId={this.props.match.params.id}
                checkCanvas={checkCanvas}
                listCountryAvalibleCanvasIt={listCountryAvalibleCanvasIt}
                city={detail?.data.cusCity}
                countryId={detail?.data.cusCountry}
                state={detail?.data.cusProvince}
                reasonOnHold={detail?.data?.onHoldReason?.reason_id}
              />
            </Spin>
          </Modal>
          <Modal
            centered
            title={<b>Mark address as valid</b>}
            footer={null}
            visible={showModal === 'mark_valid_address'}
            onCancel={() => this.setState({ showModal: null })}
            width={400}
          >
            <FormMarkValidAddress
              onSubmit={this.formMarkValidAddress}
              onCancel={() => this.setState({ showModal: null })}
              className="mt-0 pt-0"
            />
          </Modal>
          <Modal
            centered
            title={<b>Order confirmation required</b>}
            visible={showModalVariant}
            onCancel={() => {
              this.handleCloseModal();
            }}
            onOk={() => {
              this.handleDuplicateOrderManual();
            }}
            width={800}
            footer={null}
          >
            <Spin spinning={listVariantRiskLoading}>
              <div className="mb-2">
                Your order includes the following{' '}
                <a
                  href="https://help.gearment.com/en/article/order-with-variation-at-risk-8wumdy/"
                  target="blank"
                >
                  variant(s) at risk
                </a>
                :
              </div>
              {listVariantRisk?.data?.map((i) => {
                return (
                  <p>
                    <ul>
                      <li>
                        {i?.exp_brand} - {i?.color_name} - {i?.size_name}
                      </li>
                    </ul>
                  </p>
                );
              })}
              <div>
                By continuing, you confirm to create the order with the
                variant(s) at risk and acknowledge that the processing time may
                be extended for a few business days.
              </div>
            </Spin>
            <div className="d-flex align-items-right justify-content-end mt-3">
              <GMButton
                color="SecondaryGray"
                type="button"
                onClick={() => {
                  this.handleCloseModal();
                }}
                style={{ width: 170 }}
              >
                Cancel
              </GMButton>
              <GMButton
                style={{ width: 170 }}
                onClick={() => {
                  this.handleDuplicateOrderManual();
                }}
                className="ml-3"
              >
                Confirm order
              </GMButton>
            </div>
          </Modal>
        </Wrapper>
        <Modal
          centered
          title={<b>Order not ready for payment</b>}
          visible={showErrorPayment}
          onCancel={() => {
            this.handleClosePaymentModal();
          }}
          width={800}
          footer={null}
        >
          <Spin spinning={listPaymentLoading}>
            <div>
              This order cannot be processed due to some reasons, please resolve
              it and re-try:
            </div>
            {listPayment?.orderError
              ? Object.values(listPayment?.orderError)?.map((i) => {
                  return (
                    <p>
                      <ul>
                        <li>{i?.reason}</li>
                      </ul>
                    </p>
                  );
                })
              : ''}
            <div className="d-flex  align-items-right justify-content-end">
              <GMButton
                color="SecondaryGray"
                type="button"
                onClick={() => {
                  this.handleClosePaymentModal();
                }}
              >
                Cancel
              </GMButton>
            </div>
          </Spin>
        </Modal>

        {this.state.record2Remove !== null ? (
          <ConfirmDialog
            ctas={
              <>
                <GMButton
                  type="button"
                  color="SecondaryGray"
                  disabled={cancelOrderLoading}
                  onClick={() => this.setState({ record2Remove: null })}
                >
                  No, don't cancel
                </GMButton>

                <GMButton
                  type="button"
                  color="PrimaryError"
                  disabled={cancelOrderLoading}
                  onClick={this.cancelOrder}
                >
                  Yes, cancel
                </GMButton>
              </>
            }
          >
            <ConfirmModalInnerWrapper className="p-2">
              <div className="content">
                <h4 className="header pb-3">Request cancel order</h4>
                <div className="msg pb-3">
                  Are you sure you want to cancel this order?
                </div>
              </div>
            </ConfirmModalInnerWrapper>
          </ConfirmDialog>
        ) : null}

        <GlobalStyle />
      </>
    );
  }
}

export default connect(
  (state) => ({
    detail: state.order.detail.orderDetail,
    updateAddressLoading: state.order.detail.updateAddress.loading,
    markAsValidAddressLoading: state.order.detail.markAsValidAddress.loading,
    updateShippingServiceLoading:
      state.order.detail.updateShippingService.loading,
    duplicateOrderLoading: state.order.detail.duplicateOrder.loading,
    lockOrderLoading: state.order.detail.lockOrder.loading,
    rollbackLoading: state.order.items_removed.rollback.loading,
    profile: state.auth.info.profile,
    approveOrderLoading: state.order.list.approveOrder.loading,
    unapproveOrderLoading: state.order.list.unapproveOrder.loading,
    paymentOrderLoading: state.order.list.paymentOrder.loading,
    countries: state.user.profile.listCountry,
    checkAddress: state.order.detail.updateAddress.checkAddress,
    cancelOrderLoading: state.order.detail.cancelOrder.loading,
    orderNewUpdate: state.order.detail.updateAddress.orderNewUpdate,
    listVariantRiskLoading: state.order.createOrder.listVariantRisk.loading,
    listVariantRisk: state.order.createOrder.listVariantRisk,
    showModalVariant: state.order.createOrder.showModalVariant,
    showErrorPayment: state.order.list.showErrorPayment,
    listPaymentLoading: state.order.list.orderPaymentData?.loading,
    listPayment: state.order.list.orderPaymentData?.list?.data,
    logsWebhook: state.logs?.logs_webhook?.data,
    logsWebhookLoading: state.logs?.logs_webhook?.loading,
    logsOrder: state.logs?.logs_order?.data,
    logsOrderLoading: state.logs?.logs_order?.loading,
  }),
  getOrderItemActions
)(Detail);
