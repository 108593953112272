/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Tooltip } from 'antd';
import queryString from 'query-string';
import { Tabs, Modal, Dropdown, Menu, Checkbox, Button, notification } from 'antd';
import {
  getList,
  archiveOrder,
  approveOrder,
  unapproveOrder,
  syncTracking,
  verifyAddress,
  syncTrackingAmazon,
  createIssue,
  paymentOrder as paymentOrderAction,
  foreceVerifiedDelivery,
  getPaymentOrderListAction,
  exportOrders,
  setPaymentOrderData,
} from './actions';
import FormFilter from './components/FormFilter';
import getMomentInstance from 'utils/moment';
import {
  addMarginTopToCheckBoxOfAntdTable,
  checkFunctionalPermission,
  getStore,
  pagCal,
  setStore,
} from 'utils/helper';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { getListStore } from '../../../Stores/scenes/List/actions';
import { getListExproduct } from '../../../Mockups/scenes/Create/actions';
import RenderOptimizedTable from 'utils/Form/RenderOptimizedTable';
import DotLoadingIcon from 'images/dots-loading.gif';
import {
  GlobalStyle,
  Wrapper,
  ContextMenuWrapper,
  WrapperText,
} from './styled';
import { GMButton } from 'components/Button';
import { columns } from './components/columns';
import RenderListActions from 'utils/Form/RenderListActions';
import { STORAGE_NAME } from '../PaymentOrder';
import RenderConfigUserLabelWarningModal from 'utils/Form/RenderConfigUserLabelWarningModal';
import { ReactComponent as PlusIcon } from 'images/icons/plus.svg';
import { ReactComponent as EditIcon } from 'images/icons/edit-01.svg';
import { ReactComponent as SyncIcon } from 'images/icons/sync.svg';
import { ReactComponent as UploadIcon } from 'images/icons/upload-01.svg';
import { ReactComponent as DownIcon } from 'images/icons/chevron-down.svg';
import { ReactComponent as DownloadIcon } from 'images/icons/download-01.svg';
import { ReactComponent as RightIcon } from 'images/icons/chevron-right.svg';
import { ReactComponent as LeftIcon } from 'images/icons/chevron-left.svg';
import classNames from 'classnames';
import { resetDataBeforeMatchingProduct, resetSubmitStatus } from '../MatchProduct/actions';
import { Select } from 'antd';
import ExportModal from './components/ExportModal';
import SyncOrderModal from './components/SyncOrderModal';
import ImportPendingNotify from '../ImportOrder/components/ImportPendingNotify';
import { syncOrder } from '../SyncOrder/actions';
import { reset } from 'redux-form';
import { RenderMoreActions } from 'utils/Form';
import { WrapperRenderMoreAction } from 'utils/Form/components/RenderMoreAction/styled';
import GMTable from 'components/GMTable';
import { getOrderItemActions } from '../Detail';
import { checkVariantRiskAction } from '../Create/actions';
import { orderTabs } from './const';
import InsufficientGWalletModal from 'components/InsufficientGWalletModal';
import { fncCondRemap, invalidActionNotify, remapFuncArray } from 'scenes/Orders/utils';
import OrderWarning from "../../../Orders/scenes/Warning";
const { Option } = Select;
const { confirm } = Modal;

export const CONFIG_DONT_SHOW__APPROVE_ORDER =
  'CONFIG_DONT_SHOW__APPROVE_ORDER';
export const CONFIG_DONT_SHOW__UNAPPROVE_ORDER =
  'CONFIG_DONT_SHOW__UNAPPROVE_ORDER';
export const CONFIG_DONT_SHOW__VERIFY_ADDRESS_ORDER =
  'CONFIG_DONT_SHOW__VERIFY_ADDRESS_ORDER';
export const CONFIG_DONT_SHOW__SYNC_TRACKING_AMAZON_ORDER =
  'CONFIG_DONT_SHOW__SYNC_TRACKING_AMAZON_ORDER';
export const CONFIG_DONT_SHOW__SYNC_TRACKING_ORDER =
  'CONFIG_DONT_SHOW__SYNC_TRACKING_ORDER';
export const CONFIG_DONT_SHOW__CREATE_ISSUE_ORDER =
  'CONFIG_DONT_SHOW__CREATE_ISSUE_ORDER';
export const CONFIG_DONT_SHOW__FORCE_VERIFIED_DELIVER_ORDER =
  'CONFIG_DONT_SHOW__FORCE_VERIFIED_DELIVER_ORDER';

const limitIPP = (ipp) => {
  let _ipp = ipp * 1;
  if (isNaN(_ipp)) {
    return 100;
  }
  if (_ipp > 100) {
    return 100;
  }
  return _ipp || 100;
};

class List extends Component {
  constructor(props) {
    super(props);
    const { fromDate, toDate, ...query_params } = queryString.parse(
      window.location.search
    );
    this.state = {
      showModal: null,
      currIdToQuickView: null,
      selectedRowKeys: [],
      showMoreAction: false,
      initial_filter_values: {
        sortBy: 'id',
        sortType: 'desc',
        productId: '0',
        colorIds: [],
        sizeIds: [],
        trackingType: null,
        verifiedAddress: '-1',
        isApproved: '-1',
        orderNote: '-1',
        isImport: '-1',
        status: '-1',
        type: '-1',
        insertMethod: '-1',
        method: '-1',
        paymentStatus: '-1',
        isDuplicated: '-1',
        typeDate: '1',
        storeId: '0',
        rating: '0',
        orderName: '',
        date_range: [
          fromDate ? getMomentInstance(fromDate) : null,
          toDate ? getMomentInstance(toDate) : null,
        ],
        ...query_params,
      },
    };
  }

  componentDidMount() {
    const { initial_filter_values } = this.state;
    this.fetchData(initial_filter_values);
    this.props.getListStore({});
    this.props.getCountOrder({ achived: 1 });
    this.props.getListExproduct({
      sortBy: 'type',
      sortType: 'asc',
    });

    this.props.dispatch(setPaymentOrderData(null));
    this.props.dispatch(resetSubmitStatus());
    setStore(STORAGE_NAME, null);
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      JSON.stringify(prevProps.orders) !== JSON.stringify(this.props.orders)
    ) {
      const newSelectedRowKeys = this.state.selectedRowKeys.filter((id) =>
        (this.props?.orders?.data || []).find(
          (item) => parseInt(item.id) === parseInt(id)
        )
      );
      this.setState({ selectedRowKeys: newSelectedRowKeys });
    }
    addMarginTopToCheckBoxOfAntdTable(
      'ant-table-selection',
      this.state.selectedRowKeys
    );
  }

  tabList = [
    'All orders',
    'Pending',
    'Processing',
    'Completed',
    'Order shipping',
    'On-hold',
    'Canceled',
    // 'Order Matched',
    // 'Order Unmatched',
  ];

  fetchData = ({ date_range, page = 1, perPage = 100, ...options }) => {
    let params = {
      page,
      perPage: limitIPP(perPage),
      ...options,
    };
    if (date_range?.[0]) {
      params['fromDate'] = getMomentInstance(date_range[0]).format(
        'YYYY-MM-DD'
      );
    }
    if (date_range?.[1]) {
      params['toDate'] = getMomentInstance(date_range[1]).format('YYYY-MM-DD');
    }
    if (parseInt(params['isApproved']) === -1) delete params['isApproved'];
    if (parseInt(params['orderNote']) === -1) delete params['orderNote'];
    if (parseInt(params['isDuplicated']) === -1) delete params['isDuplicated'];
    if (parseInt(params['isImport']) === -1) delete params['isImport'];
    if (parseInt(params['status']) === -1) delete params['status'];
    if (parseInt(params['type']) === -1) delete params['type'];
    if (parseInt(params['method']) === -1) delete params['method'];
    if (parseInt(params['status']) === 19) {
      delete params['status'];
      params['customStatus'] = 19;
    }
    if (parseInt(params['insertMethod']) === -1) delete params['insertMethod'];
    if (parseInt(params['paymentStatus']) === -1)
      delete params['paymentStatus'];
    if (parseInt(params['storeId']) === 0) delete params['storeId'];
    if (!params['orderName']) delete params['orderName'];
    // if (!(params['trackingType'])) delete params['trackingType'];
    if (parseInt(params['verifiedAddress']) === -1)
      delete params['verifiedAddress'];
    if (parseInt(params['typeDate']) === 0) delete params['typeDate'];
    if (parseInt(params['productId']) === 0) delete params['productId'];
    if (!Array.isArray(params['colorIds']) || params['colorIds'].length === 0)
      delete params['colorIds'];
    if (!Array.isArray(params['sizeIds']) || params['sizeIds'].length === 0)
      delete params['sizeIds'];
    if (parseInt(params['colorId']) === 0) delete params['colorId'];
    if (parseInt(params['rating']) === 0) delete params['rating'];
    if (!params['fromDate']) delete params['fromDate'];
    if (!params['toDate']) delete params['toDate'];
    if (params['colorId']) delete params['colorId'];
    this.props.history.push(
      window.location.pathname + '?' + queryString.stringify(params),
      this.props.history.location.state
    );

    this.props.getList({
      ...params,
    });
  };
  onSelectChange = (selectedRowKeys) => {
    this.setState({ selectedRowKeys });
  };
  showQuickViewModal = (orderId) => {
    this.setState({ showModal: 'export_order', currIdToQuickView: orderId });
  };
  showModalSyncOrder = () => {
    this.setState({ showModal: 'sync_order' });
  };
  handleChangeTab = (activeKey) => {
    if (activeKey === '9999') {
      return;
    }
    const currrentActiveTabKey = this.tabList[activeKey];
    const qStr = queryString.parse(
      orderTabs?.[currrentActiveTabKey]?.queryString
    );
    if (!isNaN(activeKey * 1)) {
      this.fetchData({ page: 1, ...qStr });
    }
  };
  getActiveTag = (status) => {
    const items = Object.values(orderTabs);
    const str = queryString.stringify(status);
    let tmp = -1;
    for (let i = 0; i < items.length; i++) {
      const item = items[i];
      if (str.indexOf(item.queryString?.substr(1)) !== -1) {
        tmp = item;
        break;
      }
    }
    if (this.tabList.indexOf(tmp?.title) === -1) {
      if (!status?.isImport) {
        return '0';
      }
    }
    return this.tabList.indexOf(tmp?.title) + '';
  };
  archiveOrder = (ids) => {
    const { archiveOrder, orders } = this.props;
    confirm({
      mask: false,
      title: 'Do you want to archive these orders?',
      icon: <ExclamationCircleOutlined />,
      content: "You won't be able to revert this!",
      onOk() {
        archiveOrder({ orders: ids }, orders.params);
        window.scroll(0, 0);
      },
      onCancel() { },
    });
  };

  approveOrder = (ids) => {
    const { approveOrder, orders, profile } = this.props;
    if (getStore(CONFIG_DONT_SHOW__APPROVE_ORDER, profile?.id)) {
      approveOrder({ orders: ids }, orders.params);
      return;
    }
    confirm({
      mask: false,
      title: 'Do you want to approve these orders?',
      icon: <ExclamationCircleOutlined />,
      maskTransitionName: '',
      content: (
        <Checkbox
          onChange={(v) => {
            setStore(
              CONFIG_DONT_SHOW__APPROVE_ORDER,
              !!v?.target?.checked,
              profile?.id
            );
          }}
        >
          Don’t show again
        </Checkbox>
      ),
      onOk() {
        approveOrder({ orders: ids }, orders.params);
        window.scroll(0, 0);
      },
      onCancel() { },
    });
  };

  unapproveOrder = (ids) => {
    const { unapproveOrder, orders, profile } = this.props;
    if (getStore(CONFIG_DONT_SHOW__UNAPPROVE_ORDER, profile?.id)) {
      unapproveOrder({ orders: ids }, orders.params);
      return;
    }
    confirm({
      mask: false,
      title: 'Do you want to unapprove  these orders?',
      icon: <ExclamationCircleOutlined />,
      content: (
        <Checkbox
          onChange={(v) => {
            setStore(
              CONFIG_DONT_SHOW__UNAPPROVE_ORDER,
              !!v?.target?.checked,
              profile?.id
            );
          }}
        >
          Don’t show again
        </Checkbox>
      ),
      onOk() {
        unapproveOrder({ orders: ids }, orders.params);
        window.scroll(0, 0);
      },
      onCancel() { },
    });
  };

  paymentOrder = () => {
    this.props.dispatch(
      getPaymentOrderListAction({ orders: this.state.selectedRowKeys })
    );
  };

  syncTracking = (ids) => {
    const { syncTracking, orders, profile } = this.props;
    if (getStore(CONFIG_DONT_SHOW__SYNC_TRACKING_ORDER, profile?.id)) {
      syncTracking({ orders: ids }, orders.params);
      return;
    }
    confirm({
      mask: false,
      title: 'Do you want to sync tracking for these orders?',
      icon: <ExclamationCircleOutlined />,
      content: (
        <Checkbox
          onChange={(v) => {
            setStore(
              CONFIG_DONT_SHOW__SYNC_TRACKING_ORDER,
              !!v?.target?.checked,
              profile?.id
            );
          }}
        >
          Don’t show again
        </Checkbox>
      ),
      onOk() {
        syncTracking({ orders: ids }, orders.params);
        window.scroll(0, 0);
      },
      onCancel() { },
    });
  };

  syncTrackingAmazon = (ids) => {
    const { syncTrackingAmazon, orders, profile } = this.props;
    if (getStore(CONFIG_DONT_SHOW__SYNC_TRACKING_AMAZON_ORDER, profile?.id)) {
      syncTrackingAmazon({ orders: ids }, orders.params);
      return;
    }
    confirm({
      mask: false,
      title: 'Do you want to sync tracking Amazon for these orders?',
      icon: <ExclamationCircleOutlined />,
      content: (
        <Checkbox
          onChange={(v) => {
            setStore(
              CONFIG_DONT_SHOW__SYNC_TRACKING_AMAZON_ORDER,
              !!v?.target?.checked,
              profile?.id
            );
          }}
        >
          Don’t show again
        </Checkbox>
      ),
      onOk() {
        syncTrackingAmazon({ orders: ids }, orders.params);
        window.scroll(0, 0);
      },
      onCancel() { },
    });
  };

  verifyAddress = (ids) => {
    const { verifyAddress, orders, profile } = this.props;
    if (getStore(CONFIG_DONT_SHOW__VERIFY_ADDRESS_ORDER, profile?.id)) {
      verifyAddress({ orders: ids }, orders.params);
      return;
    }
    confirm({
      mask: false,
      title: 'Do you want to verify address for these orders?',
      icon: <ExclamationCircleOutlined />,
      content: (
        <Checkbox
          onChange={(v) => {
            setStore(
              CONFIG_DONT_SHOW__VERIFY_ADDRESS_ORDER,
              !!v?.target?.checked,
              profile?.id
            );
          }}
        >
          Don’t show again
        </Checkbox>
      ),
      onOk() {
        verifyAddress({ orders: ids }, orders.params);
        window.scroll(0, 0);
      },
      onCancel() { },
    });
  };
  createIssue = (ids) => {
    const redirectToCreateIssue = () => {
      this.props.history.push('/orders/order-issues/create', {
        listOrderId: ids,
      });
    };
    const { profile } = this.props;
    if (getStore(CONFIG_DONT_SHOW__CREATE_ISSUE_ORDER, profile?.id)) {
      redirectToCreateIssue();
      return;
    }
    confirm({
      mask: false,
      title: 'Do you want to create issue for these orders?',
      icon: <ExclamationCircleOutlined />,
      content: (
        <Checkbox
          onChange={(v) => {
            setStore(
              CONFIG_DONT_SHOW__CREATE_ISSUE_ORDER,
              !!v?.target?.checked,
              profile?.id
            );
          }}
        >
          Don’t show again
        </Checkbox>
      ),
      onOk() {
        redirectToCreateIssue();
      },
      onCancel() { },
    });
  };

  foreceVerifiedDelivery = (ids) => {
    const { foreceVerifiedDelivery, orders, profile } = this.props;
    if (getStore(CONFIG_DONT_SHOW__FORCE_VERIFIED_DELIVER_ORDER, profile?.id)) {
      foreceVerifiedDelivery({ orders: ids }, orders.params);
      return;
    }
    confirm({
      mask: false,
      title: 'Do you want to force verified deliver for these orders?',
      icon: <ExclamationCircleOutlined />,
      content: (
        <Checkbox
          onChange={(v) => {
            setStore(
              CONFIG_DONT_SHOW__FORCE_VERIFIED_DELIVER_ORDER,
              !!v?.target?.checked,
              profile?.id
            );
          }}
        >
          Don’t show again
        </Checkbox>
      ),
      onOk() {
        foreceVerifiedDelivery({ orders: ids }, orders.params);
      },
      onCancel() { },
    });
  };

  matchProduct = () => {
    this.props.dispatch(resetDataBeforeMatchingProduct());
    this.props.history.push('/orders/match-product', {
      listOrderId: this.state.selectedRowKeys,
    });
  };
  handleOnExport = () => {
    let params = { ...this.props.AdvancedFilterOrder?.values };
    let paramsUrl = queryString.parse(window.location.search);
    this.setState({ showModal: null });

    if (parseInt(params['type']) === -1) delete params['type'];
    if (parseInt(params['isApproved']) === -1) delete params['isApproved'];
    if (parseInt(params['orderNote']) === -1) delete params['orderNote'];
    if (parseInt(params['isDuplicated']) === -1) delete params['isDuplicated'];
    if (parseInt(params['isImport']) === -1) delete params['isImport'];
    if (parseInt(params['method']) === -1) delete params['method'];
    if (parseInt(params['status']) === -1) delete params['status'];
    if (parseInt(params['insertMethod']) === -1) delete params['insertMethod'];
    if (parseInt(params['paymentStatus']) === -1)
      delete params['paymentStatus'];
    if (parseInt(params['storeId']) === 0) delete params['storeId'];
    if (parseInt(params['verifiedAddress']) === -1)
      delete params['verifiedAddress'];
    if (parseInt(params['typeDate']) === 0) delete params['typeDate'];
    if (parseInt(params['productId']) === 0) delete params['productId'];
    if (!Array.isArray(params['sizeIds']) || params['sizeIds'].length === 0)
      delete params['sizeIds'];
    if (!Array.isArray(params['colorIds']) || params['colorIds'].length === 0)
      delete params['colorIds'];
    if (!params['fromDate']) delete params['fromDate'];
    if (!params['toDate']) delete params['toDate'];
    if (!params['orderName']) delete params['orderName'];

    //Export order with filter
    if (parseInt(this.props.valueOrderExport) === 4) {
      this.props.exportOrders({
        export: 1,
        ...params,
      });
    } else if (parseInt(this.props.valueOrderExport) === 2) {
      //Export order all page
      this.props.exportOrders({
        export: 1,
      });
    } else {
      //Export order current page
      this.props.exportOrders({
        export: 1,
        page: paramsUrl?.page,
        perPage: paramsUrl?.perPage,
      });
    }
  };
  handleSyncOrder = () => {
    let values = this.props.FormSyncOrder.values;
    const dataToSync = {
      platformId: values?.platformId || '',
      storeId: values.storeId || '',
      fromDate:
        values.date_range && values.date_range[0]
          ? values.date_range[0].format('YYYY-MM-DD')
          : '',
      toDate:
        values.date_range && values.date_range[1]
          ? values.date_range[1].format('YYYY-MM-DD')
          : '',
    };
    this.props.syncOrder(dataToSync);
    this.props.resetForm('FormSyncOrder');

    this.setState({ showModal: null });
  };

  getItemOrderActions = (item) => {
    return {
      archiveOrder: () => {
        const { archiveOrder } = this.props.orderItemActions;
        const id = item.id;
        confirm({
          mask: false,
          title: 'Do you want to archive this order?',
          icon: <ExclamationCircleOutlined />,
          content: "You won't be able to revert this!",
          onOk() {
            archiveOrder({ orders: [id], from: 'listOrder' });
          },
          onCancel() { },
        });
      },
      handleDuplicateOrderManual: () => {
        window.scroll(0, 0);
        this.props.closeVariantRiskAction();
        this.props.duplicateOrder({ id: item?.id });
        this.props.resetReducerAction();
      },
      handleCloseModal: () => {
        this.props.closeVariantRiskAction();
      },
      handleClosePaymentModal: () => {
        this.props.closeModalWarningPaymentAction();
      },
      duplicateOrder: () => {
        if (parseInt(item?.platformId) === 9) {
          this.props.history.push(`/orders/${item?.id}/duplicate`);
        } else {
          let data = [];
          this.props.dispatch(
            checkVariantRiskAction({
              data: { data: data },
              submitData: item?.id,
              form: 'duplicate_order_manual',
              cb: () => {
                // console.log('#############');
              },
            })
          );
        }
      },
      convertOrderLabel: () => {
        this.props.history.push(
          `/orders/${this.props.detail?.data?.id}/convert-order-label`
        );
      },
      lockOrder: () => {
        const { lockOrder } = this.props.orderItemActions;
        const id = item.id;
        confirm({
          mask: false,
          title: 'Do you want to lock this order?',
          icon: <ExclamationCircleOutlined />,
          content: "You won't be able to revert this!",
          onOk() {
            lockOrder(id);
          },
          onCancel() { },
        });
      },
      approveOrder: () => {
        const { approveOrder } = this.props.orderItemActions;
        const id = item.id;

        const { orders } = this.props;

        confirm({
          mask: false,
          title: 'Do you want to approve this order?',
          icon: <ExclamationCircleOutlined />,
          content: "You won't be able to revert this!",
          onOk() {
            approveOrder({ orders: [id] }, orders.params);
          },
          onCancel() { },
        });
      },
      unapproveOrder: () => {
        const { unapproveOrder } = this.props.orderItemActions;
        const id = item.id;
        const { orders } = this.props;
        confirm({
          mask: false,
          title: 'Do you want to unapprove this order?',
          icon: <ExclamationCircleOutlined />,
          content: "You won't be able to revert this!",
          onOk() {
            unapproveOrder(
              { orders: [id], from: 'detailOrder' },
              orders.params
            );
          },
          onCancel() { },
        });
      },
      createIssue: () => {
        const redirectToCreateIssue = () => {
          this.props.history.push('/orders/order-issues/create', {
            listOrderId: [item?.id],
          });
        };
        confirm({
          mask: false,
          title: 'Do you want to create issue for these orders?',
          icon: <ExclamationCircleOutlined />,
          content: "You won't be able to revert this!",
          onOk() {
            //this.props.createIssue({orders: ids})
            redirectToCreateIssue();
          },
          onCancel() { },
        });
      },
      viewInvoice: () => {
        const id = item.id;
        this.props.history.push(`/orders/${id}/invoice`);
      },
      paymentOrder: () => {
        this.props.dispatch(getPaymentOrderListAction({ orders: [item?.id] }));
      },
    };
  };

  render() {
    const {
      orders,
      orderData,
      pageName,
      syncTrackingLoading,
      syncTrackingAmazonLoading,
      archiveOrderLoading,
      approveOrderLoading,
      unapproveOrderLoading,
      paymentOrderLoading,
      stores,
      exportLoading,
      exproducts,
      verifyAddressLoading,
      profile,
      foreceVerifiedDeliveryLoading,
      syncOrderLoading,
      duplicateOrderLoading,
      orderPaymentDataLoading,
      listVariantRiskLoading,
    } = this.props;
    const { initial_filter_values, selectedRowKeys } = this.state;
    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
      hideDefaultSelections: true,
    };
    let listActions = [];
    listActions.unshift({
      key: 'archived_order',
      text: <WrapperText>Archive order</WrapperText>,
      onSelect: () => {
        this.archiveOrder(selectedRowKeys);
      },
    });
    listActions.unshift({
      key: 'create_issue',
      text: 'Create issue',
      onSelect: () => {
        this.createIssue(selectedRowKeys);
      },
    });
    listActions.unshift({
      key: 'sync_tracking_amazon',
      text: 'Sync tracking - Order Amazon',
      onSelect: () => {
        this.syncTrackingAmazon(selectedRowKeys);
      },
    });
    listActions.unshift({
      key: 'sync_tracking',
      text: 'Sync tracking',
      onSelect: () => {
        this.syncTracking(selectedRowKeys);
      },
    });
    listActions.unshift({
      key: 'unapprove_order',
      text: 'Unapprove order',
      onSelect: () => {
        this.unapproveOrder(selectedRowKeys);
      },
    });
    checkFunctionalPermission(profile, 'approveOrderPermission') &&
      listActions.unshift({
        key: 'approve_order',
        text: 'Approve order',
        onSelect: () => {
          this.approveOrder(selectedRowKeys);
        },
      });
    (profile?.primaryId || 0) * 1 === 0 &&
      listActions.unshift({
        key: 'payment_order',
        text: 'Make payment',
        onSelect: () => {
          this.paymentOrder(selectedRowKeys);
        },
      });
    // listActions.unshift({
    //   key: 'force_verified_delivery',
    //   text: 'Force verified delivery',
    //   onSelect: () => {
    //     this.foreceVerifiedDelivery(selectedRowKeys);
    //   },
    // });
    // checkFunctionalPermission(profile, 'matchOrderPermission') &&
    //   listActions.unshift({
    //     key: 'match_product',
    //     text: 'Match product',
    //     onSelect: () => {
    //       this.matchProduct(selectedRowKeys);
    //     },
    //   });

    listActions.unshift({
      key: 'verify_address',
      text: 'Verify address',
      onSelect: () => {
        this.verifyAddress(selectedRowKeys);
      },
    });
    // listActions.unshift({
    //   key: 'match_product',
    //   text: 'Match product',
    //   onSelect: () => {
    //     this.matchProduct(selectedRowKeys);
    //   },
    // });
    const selectedItems = (orderData || []).filter(o => (this.state.selectedRowKeys || []).indexOf(o.id));
    listActions = remapFuncArray(
      selectedItems,
      listActions,
      () => notification.warning({ message: invalidActionNotify, duration: 5 })
    );
    const sideSelectFncs = fncCondRemap(selectedItems, {
      matchProduct: this.matchProduct,
      paymentOrder: this.paymentOrder,
    }, () => notification.warning({ message: invalidActionNotify, duration: 5 }));

    const SubMenu = (
      <Menu className="orders-tabs-menu">
        <Menu.Item>
          <Link to="/orders/deleted-order">Archived</Link>
        </Menu.Item>
        <Menu.Item>
          <span onClick={() => this.fetchData({ page: 1, isImport: 1 })}>
            Matching
          </span>
        </Menu.Item>
        <Menu.Item>
          <span onClick={() => this.fetchData({ page: 1, isImport: 2 })}>
            Non-matching
          </span>
        </Menu.Item>
      </Menu>
    );
    const qStr = queryString.parse(window.location.search);
    const isLoading =
      orders.loading ||
      exproducts.loading ||
      syncTrackingLoading ||
      syncTrackingAmazonLoading ||
      archiveOrderLoading ||
      approveOrderLoading ||
      unapproveOrderLoading ||
      paymentOrderLoading ||
      orderPaymentDataLoading ||
      exportLoading ||
      verifyAddressLoading ||
      foreceVerifiedDeliveryLoading ||
      syncOrderLoading ||
      duplicateOrderLoading ||
      stores.loading || listVariantRiskLoading;

    const pagingInfo = pagCal({
      currentPage: this.props?.orders?.params?.page * 1,
      itemPerPage: this.props?.orders?.params?.perPage || 0,
      totalItem: this.props?.orders?.total || 0,
    });
    const pager = this.props?.orders?.total ? (
      <div className="gmpagination">
        <div className="gmpagination-innerwrapper">
          <div className="gmpagination-left">
            {/* <div className="gmpagination-showing">
              Showing{' '}
              {((this.props?.orders?.params?.page || 1) - 1) *
                this.props?.orders?.params?.perPage +
                1}
              -
              {(this.props?.orders?.params?.page || 1) *
                this.props?.orders?.params?.perPage}{' '}
              of {this.props?.orders?.total?.toLocaleString('en-US')}
            </div> */}
            {/* <div className="gmpagination-separator"></div> */}
            <div className="gmpagination-rrp">
              Rows per page &nbsp;
              <Dropdown
                trigger="click"
                overlay={
                  <ContextMenuWrapper>
                    {[20, 50, 100].map((ipp) => {
                      return (
                        <a
                          key={ipp + '_pp'}
                          href=""
                          onClick={(evt) => {
                            evt.preventDefault();
                            const perPage = ipp || 100;
                            const page = 1;
                            this.fetchData({
                              ...orders.params,
                              perPage,
                              page,
                            });
                            return false;
                          }}
                        >
                          <span>{ipp}</span>
                        </a>
                      );
                    })}
                  </ContextMenuWrapper>
                }
                placement="topCenter"
              >
                <div>
                  {limitIPP(qStr?.perPage)}
                  <DownIcon />
                </div>
              </Dropdown>
            </div>
          </div>
          <div className="gmpagination-right">
            <GMButton
              color="SecondaryColor"
              type="button"
              className="gmpagination-btn"
              disabled={isLoading || qStr?.page * 1 <= 1}
              onClick={() => {
                const perPage = limitIPP(qStr?.perPage);
                const page = (qStr?.page || 1) * 1 - 1;
                this.fetchData({ ...orders.params, perPage, page });
                return false;
              }}
            >
              <LeftIcon />
            </GMButton>
            {pagingInfo.map(p => {
              if (p.dummy) {
                return (<span>...</span>);
              }
              return (
                <a
                  href="#"
                  onClick={() => {
                    const perPage = limitIPP(qStr?.perPage);
                    const page = p.page;
                    this.fetchData({ ...orders.params, perPage, page });
                    return false;
                  }}
                  className={classNames('page', { current: p.isCurrent })}
                >
                  {p.page}
                </a>
              );
            })}
            <GMButton
              color="SecondaryColor"
              type="button"
              className="gmpagination-btn"
              disabled={
                isLoading ||
                (qStr?.page * 1 >= 1 && orders?.data?.length <= 0) ||
                orders?.data?.length < limitIPP(qStr?.perPage) * 1
              }
              onClick={() => {
                const perPage = limitIPP(qStr?.perPage);
                const page = (qStr?.page || 1) * 1 + 1;
                this.fetchData({ ...orders.params, perPage, page });
                return false;
              }}
            >
              <RightIcon />
            </GMButton>
          </div>
        </div>
      </div>
    ) : null;
    return (
      <>
        <Wrapper
          pageName={pageName}
          extendPageName="List of order created within the last 6 months"
          noContainer
          ctas={
            <>
              <GMButton
                color={'SecondaryGray'}
                onClick={this.showQuickViewModal}
              >
                <DownloadIcon width="20" height="20" /> Export order
              </GMButton>
              {checkFunctionalPermission(profile, 'importOrderPermission') && (
                <GMButton
                  color={'SecondaryGray'}
                  onClick={() => this.props.history.push('/orders/import')}
                >
                  <UploadIcon width="20" height="20" /> Import order
                </GMButton>
              )}
              <Dropdown
                trigger="click"
                overlay={
                  <ContextMenuWrapper>
                    {checkFunctionalPermission(
                      profile,
                      'createOrderPermission'
                    ) && (
                        <>
                          {' '}
                          <Link to={`/orders/create`}>
                            <span>
                              <PlusIcon />
                              Manual order
                            </span>
                          </Link>
                          {
                            profile.allowLabelOrder === 0 ?
                            (
                              <Tooltip title="Label order is not available">
                                  <Link 
                                    to='#'
                                    className="disable-link"
                                    onClick={ (event) => event.preventDefault() }
                                  >
                                  <span>
                                    <EditIcon />
                                    Label order
                                  </span>
                                  </Link>
                              </Tooltip>
                              
                            ) :
                            (
                              <Link to={`/orders/create-label-order`}>
                              <span>
                                <EditIcon />
                                Label order
                              </span>
                              </Link>
                            )
                            
                          }
                        </>
                      )}

                    {checkFunctionalPermission(
                      profile,
                      'syncOrderPermission'
                    ) && (
                        <div
                          onClick={this.showModalSyncOrder}
                          className="sync-order p-2"
                        >
                          <span>
                            <SyncIcon className="sync" />
                            Sync order
                          </span>
                        </div>
                      )}
                  </ContextMenuWrapper>
                }
                placement="bottomLeft"
                arrow
              >
                <GMButton type="button" className="addnew">
                  <PlusIcon className="plusicon" />
                  Create new order
                </GMButton>
              </Dropdown>
            </>
          }
        >
          <OrderWarning className="no-billing-info-warning" />

          <div className="filter">
            <FormFilter
              exproducts={exproducts.data}
              stores={stores.data}
              showModal={(name) => {
                this.setState({ showModal: name });
              }}
              profile={profile}
              initialValues={initial_filter_values}
              onSubmit={(params) => this.fetchData({ ...params, page: 1 })}
              handleAdvancedSubmit={this.fetchData}
              is_mobile={this.props.is_mobile}
            />
          </div>

          <div className="main-content">
            <div className="toppager">
              {pager}
            </div>
            <Tabs
              defaultActiveKey={this.getActiveTag(orders?.params)}
              // activeKey={this.getActiveTag(orders?.params)}
              onChange={this.handleChangeTab}
            >
              {this.tabList.map((tab, i) => {
                return (
                  <Tabs.TabPane
                    tab={
                      tab
                    }
                    key={i}
                  />
                );
              })}
              <Tabs.TabPane
                tab={
                  <Dropdown
                    placement="bottomCenter"
                    arrow
                    overlay={SubMenu}
                    trigger={['click']}
                  >
                    <span className="font-weight-bold font-size-20">...</span>
                  </Dropdown>
                }
                key={9999}
              />
            </Tabs>
            <div
              className={classNames('table order-table')}
            >
              <GMTable
                className={classNames("gm-table", { 'more-action': this.state.selectedRowKeys?.length > 0 })}
                rowKey="id"
                size="small"
                scroll={{ x: 1300 }}
                rowSelection={rowSelection}
                columns={columns({
                  listActions,
                  rowSelection,
                  profile,
                  state: this.state,
                  orderItemActions: this.getItemOrderActions,
                })}
                dataSource={orderData}
                loading={isLoading}
                pagination={false}
                sticky
              />

              {/* <RenderOptimizedTable
                rowKey="id"
                size="small"
                scroll={{ x: 1300 }}
                rowSelection={rowSelection}
                columns={columns({
                  listActions,
                  rowSelection,
                  profile,
                  state: this.state,
                })}
                dataSource={orders.data}
                loading={isLoading}
                pagination={false}
                sticky
              /> */}
              {this.state.selectedRowKeys?.length > 0 && (
                <WrapperRenderMoreAction className="more-action">
                  <div className="fixed-button">
                    <div className="deleted">
                      <div className="mr-2 items-selected">
                        {selectedRowKeys?.length} Item(s) selected
                      </div>
                      <GMButton
                        type="button"
                        color="SecondaryGray"
                        className="mr-2 blue-btn"
                        size="sm"
                        onClick={() => this.setState({ selectedRowKeys: null })}
                      >
                        Deselect all
                      </GMButton>
                    </div>
                    <div className="action">
                      <GMButton
                        className="mr-2"
                        type="button"
                        color="SecondaryGray"
                        size="sm"
                        onClick={sideSelectFncs.matchProduct}
                      >
                        Match product
                      </GMButton>
                      <GMButton
                        className="mr-2"
                        type="button"
                        color="SecondaryGray"
                        size="sm"
                        onClick={sideSelectFncs.paymentOrder}
                      >
                        Make payment
                      </GMButton>
                      <RenderMoreActions
                        title={'orders'}
                        actions={listActions}
                        selectedRowKeys={this.state.selectedRowKeys}
                      />
                    </div>
                  </div>
                </WrapperRenderMoreAction>
              )}
              {pager}
            </div>
          </div>

          <Modal
            visible={this.state.showModal === 'export_order'}
            title={<b>Export orders</b>}
            footer={null}
            onCancel={() => this.setState({ showModal: null })}
          >
            <ExportModal
              history={this.props.history}
              orderIdToQuickView={this.state.currIdToQuickView}
              dataFilter={this.props.AdvancedFilterOrder?.values}
            />
            <div className="text-right">
              <GMButton
                color={'SecondaryGray'}
                onClick={() => this.setState({ showModal: null })}
              >
                Cancel
              </GMButton>
              <GMButton className="ml-2" onClick={this.handleOnExport}>
                Export
              </GMButton>
            </div>
          </Modal>
          <Modal
            className="sync-order-modal"
            visible={this.state.showModal === 'sync_order'}
            title={<>Sync order</>}
            footer={null}
            onCancel={() => this.setState({ showModal: null })}
          >
            <SyncOrderModal
              history={this.props.history}
              orderIdToQuickView={this.state.currIdToQuickView}
              dataFilter={this.props.AdvancedFilterOrder?.values}
              stores={stores.data}
            />
            <div className="text-right pt-4">
              <GMButton
                color={'SecondaryGray'}
                onClick={() => this.setState({ showModal: null })}
              >
                Cancel
              </GMButton>
              <GMButton className="ml-2" onClick={this.handleSyncOrder}>
                Sync order
              </GMButton>
            </div>
          </Modal>
        </Wrapper>
        <RenderConfigUserLabelWarningModal profile={profile} />
        <ImportPendingNotify history={this.props.history} />
        <InsufficientGWalletModal />
        <GlobalStyle />
      </>
    );
  }
}

export default React.memo(
  connect(
    (state) => ({
      orders: state.order.list.orders,
      orderData: state.order.list.orders?.data || [],
      exportLoading: state.order.list.export.loading,
      countOrder: state.order.list.countOrder,
      syncTrackingLoading: state.order.list.syncTracking.loading,
      syncTrackingAmazonLoading: state.order.list.syncTrackingAmazon.loading,
      foreceVerifiedDeliveryLoading:
        state.order.list.foreceVerifiedDelivery.loading,
      archiveOrderLoading: state.order.list.archiveOrder.loading,
      approveOrderLoading: state.order.list.approveOrder.loading,
      unapproveOrderLoading: state.order.list.unapproveOrder.loading,
      paymentOrderLoading: state.order.list.paymentOrder.loading,
      duplicateOrderLoading: state.order.detail.duplicateOrder.loading,
      verifyAddressLoading: state.order.list.verifyAddress.loading,
      stores: state.store.list.stores2,
      exproducts: state.mockup.create.exproducts,
      profile: state.auth.info.profile,
      FormFilterOrder: state.form.formFilterOrderList,
      AdvancedFilterOrder: state.form.AdvancedFilterOrders,
      FormSyncOrder: state.form.FormSyncOrder,
      valueOrderExport: state.form.FormOrderExport?.values?.exports,
      syncOrderLoading: state.order.syncOrder.syncOrder.loading,
      orderPaymentDataLoading: state.order.list.orderPaymentData.loading,
      listVariantRiskLoading: false,
    }),
    (dispatch) => ({
      dispatch,
      getList: (options) => {
        dispatch(getList(options));
      },
      archiveOrder: (data) => {
        dispatch(archiveOrder(data));
      },
      approveOrder: (data) => {
        dispatch(approveOrder(data));
      },
      verifyAddress: (data) => {
        dispatch(verifyAddress(data));
      },
      paymentOrder: (data) => {
        dispatch(paymentOrderAction(data));
      },
      unapproveOrder: (data) => {
        dispatch(unapproveOrder(data));
      },
      syncTracking: (data) => {
        dispatch(syncTracking(data));
      },
      syncTrackingAmazon: (data) => {
        dispatch(syncTrackingAmazon(data));
      },
      createIssue: (data, params) => {
        dispatch(createIssue(data, params));
      },
      foreceVerifiedDelivery: (data, params) => {
        dispatch(foreceVerifiedDelivery(data, params));
      },
      getCountOrder: () => {
        // dispatch(countOrder(params));
      },
      getListStore: (options) => {
        dispatch(getListStore(options));
      },
      getListExproduct: (params) => {
        dispatch(getListExproduct(params));
      },
      exportOrders: (params) => {
        dispatch(exportOrders(params));
      },
      syncOrder: (data) => {
        dispatch(syncOrder(data));
      },
      resetForm: (formName) => {
        dispatch(reset(formName));
      },
      orderItemActions: getOrderItemActions(dispatch),
    })
  )(List)
);
