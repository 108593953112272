import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import {
  Field,
  reduxForm,
  FieldArray,
  arrayPush,
  arrayRemove,
  change,
  formValueSelector,
  submit,
} from 'redux-form';
import { Affix } from 'antd';
import { connect } from 'react-redux';
import validate from './validate';
import {
  renderInputText,
  renderRadioAnt,
  renderSelectAnt,
  renderTextarea,
  renderOrderItemsFBA,
  renderShippingService,
} from 'utils/Form';
import RenderUploadMultipleBarcodeFile from 'utils/Form/components/RenderUploadMultipleBarcodeFile';
import RenderCreateOrderFBA from 'utils/Form/components/RenderCreateOrderFBA';

import { list_shipping_services } from 'utils/options';
import { WS_API } from 'web.config';
import RenderAddressInput from 'utils/Form/RenderAddressInput';
import Tour from 'reactour';
import Cookies from 'js-cookie';
// eslint-disable-next-line no-unused-vars
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import tour from 'images/tour.svg';
import * as Scroll from 'react-scroll';
import { Wrapper } from './styled';
import { GMButton } from 'components/Button';
import { GMTextbox } from 'components/InputFields';
import renderRadioTopUp from 'utils/Form/components/renderRadioTopUp';

class index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      stepCount: 1,
      isTourOpen: false,
      clickDropdown: false,
      countClick: 0,
      avalibleGiftMessage: false,
    };
  }
  componentDidUpdate(prevProps, prevState) {
    if (this.props.shippingService !== prevProps.shippingService) {
      this.props.dispatch(change('FormCreateOrderLabel', 'barcodeFiles', []));
    }
  }
  cancelAction() {
    let id = window.location.pathname.split('/')[2];
    window.location.replace(`/orders/${id}`);
  }
  render() {
    const {
      handleSubmit,
      exproducts,
      stores,
      profile,
      shippingService,
      changeFieldValue,
      isUploadShippingLabelRequired,
    } = this.props;
    return (
      <Wrapper>
        {profile.allowLabelOrder === 0 && <Redirect from="*" to="/errors/404" />}
        <form onSubmit={handleSubmit}>
          <div className="p-0 mb-5 pb-5">
            <div>
              <div className="row col-md-12 customer-infor mx-0">
                <div className="col-12 mb-3">
                  <b>Platform's information</b>
                </div>
                <div className="form-group col-md-6">
                  <label className="col-form-label">
                    Store <span className="text-muted">(Optional)</span>
                  </label>
                  <GMTextbox
                    name="storeId"
                    component={renderSelectAnt}
                    colSpan={24}
                    placeholder="-- Select store --"
                    options={[
                      { value: '0', label: '-- Select store --' },
                      ...stores.map((store) => ({
                        label: (
                          <p className={'my-0'}>
                            {(store?.backendType || '')?.toUpperCase() +
                              ' - ' +
                              (store.showName || '') +
                              (store?.hasGiftMessage === true
                                ? ' (*) Has gift message'
                                : '')}
                          </p>
                        ),
                        value: store.id,
                      })),
                    ]}
                    onChange={(v) => {
                      const store = stores.find((store) => store.id == v);
                      if (store?.hasGiftMessage === true) {
                        this.setState({ avalibleGiftMessage: true });
                      } else {
                        this.setState({ avalibleGiftMessage: false });
                      }
                    }}
                    onClick={() => {
                      this.setState({
                        countClick: this.state.countClick + 1,
                      });
                    }}
                  />
                </div>
                <div className="form-group col-md-6">
                  <label className="col-form-label">
                    Reference id <span className="text-muted">(Optional)</span>
                  </label>
                  <GMTextbox
                    name="orderId"
                    maxLength={'50'}
                    placeholder="Enter reference id"
                  />
                </div>
                <div className="col-12 mb-3">
                  <div>
                    <b>Shipping service</b>
                  </div>
                  <div className="col-12 mb-3 p-0">
                    <Field
                      name="shippingService"
                      component={renderShippingService}
                      options={list_shipping_services}
                      onChange={() => {
                        changeFieldValue('barcodeIds', []);
                        if (Array.isArray(this.props.listOrderItems)) {
                          this.props.listOrderItems.forEach((_item, index) => {
                            changeFieldValue(`listOrderItems[${index}].quantity`, '0');
                          });
                        }
                      }}
                    />
                  </div>
                </div>

                <div className="form-group col-md-6">
                  <div>
                    <b>Upload shipping labels (Package shipping labels)</b>
                  </div>
                  <div className="col-12 mb-3 p-0">
                    <FieldArray
                      name="barcodeFiles"
                      paramName="barcode"
                      url={`${WS_API}/orders/upload-barcode`}
                      component={RenderUploadMultipleBarcodeFile}
                      pushArray={(value) => {
                        this.props.pushArray('barcodeIds', value);
                      }}
                      removeArray={(index) => {
                        this.props.removeArray('barcodeIds', index);
                      }}
                      clearFileIds={() => {
                        changeFieldValue('barcodeIds', []);
                      }}
                      setListFiles={(listFiles) => {
                        changeFieldValue('barcodeFiles', listFiles);
                      }}
                      isUploadShippingLabelRequired={
                        isUploadShippingLabelRequired
                      }
                      shippingService={shippingService}
                      cusId={profile?.id}
                      error
                    />
                  </div>
                </div>
                <div className="form-group col-md-6">
                  <label className="col-form-label">
                    Order gift message{' '}
                    {this.state.avalibleGiftMessage === true ? (
                      <span className="text-danger">(*) Has gift message</span>
                    ) : (
                      <span className="text-muted">(Optional)</span>
                    )}
                  </label>
                  <GMTextbox
                    name="orderNote"
                    component={renderTextarea}
                    placeholder="Enter a gift message"
                    rows={6}
                    maxLength={'350'}
                  />
                </div>
              </div>

              <div className="col-12 col-md-12 product-infor">
                <div className="form-group">
                  <FieldArray
                    name="listOrderItems"
                    exproducts={exproducts}
                    cusId={profile.id}
                    component={RenderCreateOrderFBA}
                    data={this.props.listOrderItems}
                    changeField={this.props.changeFieldValue}
                    shippingService={shippingService}
                  />
                </div>
              </div>
            </div>
          </div>

          <Affix offsetBottom={0} className="footer-order">
            <div
              className="d-flex position-fixed justify-content-end bg-white py-3 px-5 text-white mt-5"
              style={{ bottom: '0', left: '0', width: '100%' }}
            >
              <GMButton
                className="mr-3"
                color={'SecondaryGray'}
                onClick={this.cancelAction}
                type="button"
              >
                Cancel
              </GMButton>
              <GMButton>
                {window?.location?.pathname?.indexOf('convert') !== -1
                  ? 'Convert to label order'
                  : 'Create'}
              </GMButton>
            </div>
          </Affix>
        </form>
      </Wrapper>
    );
  }
}

const selector = formValueSelector('FormCreateOrderLabel');
export default connect(
  (state) => ({
    listOrderItems: selector(state, 'listOrderItems') || [],
    shippingService: selector(state, 'shippingService') || 9,
    address2: selector(state, 'address02') || '',
    zipcode: selector(state, 'zipcode') || '',
    state: selector(state, 'state') || '',
    city: selector(state, 'city') || '',
    phoneNumber: selector(state, 'phoneNumber') || '',
    countryCode: selector(state, 'countryCode') || '',
    barcodeFiles: selector(state, 'barcodeFiles') || '',
  }),
  (dispatch) => ({
    changeFieldValue: function (field, value) {
      dispatch(change('FormCreateOrderLabel', field, value));
    },
    pushArray: function (field, value) {
      dispatch(arrayPush('FormCreateOrderLabel', field, value));
    },
    removeArray: function (field, index) {
      dispatch(arrayRemove('FormCreateOrderLabel', field, index));
    },
    dispatch,
  })
)(
  reduxForm({
    form: 'FormCreateOrderLabel',
    enableReinitialize: true,
    validate,
  })(index)
);
